import React from "react";
import Template from "../../components/Template";
import './Sitemap.css';

const Sitemap = props => {
    return (
        <Template title="מפת האתר">
            <strong className="vgs">
                <ul>
                    <li aria-level="1">
                        <p role="presentation">ידידים הסדרים פנסיוניים</p>
                        <ul>
                            <li aria-level="1">
                                <p role="presentation"><a href="https://yedidim.co.il/about">מי אנחנו</a></p>
                            </li>
                            <li aria-level="1">
                                <p role="presentation"><a href="https://yedidim.co.il/product">מעגל הלווי של ידידים</a></p>
                            </li>
                            <li aria-level="1">
                                <p role="presentation"><a href="https://yedidim.co.il/package">מעטפת ידידים למעסיקים</a></p>
                            </li>
                            <li aria-level="1">
                                <p role="presentation"><a href="https://yedidim.co.il/structure-organization">מבנה ארגוני</a></p>
                            </li>
                            <li aria-level="1">
                                <p role="presentation"><a href="https://yedidim.co.il/customer">לקוחות</a></p>
                            </li>
                        </ul>
                    </li>
                    <li aria-level="1">
                        <p role="presentation">פתרונות</p>
                        <ul>

                            <li aria-level="1">
                                <p role="presentation"><a href="https://yedidim.co.il/pension-management">ניהול הסדר פנסיוני לעובד ובני משפחתו</a></p>
                            </li>
                            <li aria-level="1">
                                <p role="presentation"><a href="https://yedidim.co.il/insurance-protections">הגנות ביטוחיות</a></p>
                            </li>
                            <li aria-level="1">
                                <p role="presentation"><a href="https://yedidim.co.il/collectives">פוליסות קולקטיביות</a></p>
                            </li>
                            <li aria-level="1">
                                <p role="presentation"><a href="https://yedidim.co.il/thales">״תאלס״ - ניהול עושר פיננסי</a></p>
                            </li>
                            <li aria-level="1">
                                <p role="presentation"><a href="https://yedidim.co.il/abroad">ביטוח נסיעות לחו״ל</a></p>
                            </li>

                        </ul>

                        <li aria-level="1">
                            <p role="presentation">רגעים בחיים</p>

                            <ul>


                                <li aria-level="1">
                                    <p role="presentation"><a href="https://yedidim.co.il/start-new-work">התחלת עבודה חדשה</a></p>
                                </li>
                                <li aria-level="1">
                                    <p role="presentation"><a href="https://yedidim.co.il/life-changes">שינויים במהלך העבודה</a></p>
                                </li>
                                <li aria-level="1">
                                    <p role="presentation"><a href="https://yedidim.co.il/finish-work">עזיבת עבודה</a></p>
                                </li>
                                <li aria-level="1">
                                    <p role="presentation"><a href="https://yedidim.co.il/retirement-process">תהליך פרישה לגמלאות</a></p>
                                </li>
                                <li aria-level="1">
                                    <p role="presentation"><a href="https://yedidim.co.il/process-health-insurance">תהליך הגשת תביעה</a></p>
                                </li>

                            </ul>
                            <li aria-level="1">
                                <p role="presentation"><a href="https://yedidim.co.il/service">אמנת השירות של ידידים</a></p>
                            </li>
                            <li aria-level="1">
                                <p role="presentation"><a href="https://yedidim.co.il/forms">ידידים לטפסים פנימיים</a></p>
                            </li>
                            <li aria-level="1">
                                <p role="presentation"><a href="https://yedidim.co.il/#section_6">צור קשר</a></p>
                            </li>
                            <li aria-level="1">
                                <p role="presentation"><a href="https://info.yedidim.co.il/yedidimportaltest/loginEmployer">כניסה למעסיקים</a></p>
                            </li>
                            <li aria-level="1">
                                <p role="presentation"><a href="https://info.yedidim.co.il/AnYedidim/websiteRegistration.aspx">התחברות למערכת</a></p>
                            </li>
                        </li>
                    </li>
                    <li aria-level="1">
                        <p role="presentation"><a href="https://yedidim.co.il/disclosure">גילוי נאות</a></p>
                    </li>
                        
                            <li aria-level="1">
                        <p role="presentation"><a href="https://yedidim.co.il/accompaniment-circle">מעגל הלווי של ידידים</a></p>
                    </li>                    
                </ul>
            </strong>
        </Template>
    );
};

export default Sitemap;
