const menuPanel = [
  [{
      title: "קצת עלינו",
      anchor: [{
          title: "אודות",
          url: "/about"
        },

        {
          title: "לקוחות",
          url: "/customer"
        },
        {
          title: "מבנה ארגוני",
          url: "/structure-organization"
        },
        {
          title: "מעגל הלווי של ידידים",
          url: "/product"
        },
        {
          title: "אמנת שירות",
          url: "/service"
        },
      ]
    },
    {
      title: "בנוסף",
      anchor: [{
          title: "ניהול מערך הגנות ביטוחי",
          url: "/insurance-protections"
        },
        {
          title: "ניהול עושר פיננסי",
          url: "/thales"
        }
      ]
    },
  ],
  [{
    title: "פתרונות בשבילך",
    anchor: [
      {
        title: 'ביטוח חו"ל',
        url: "/abroad"
      },
      {
        title: "ביטוחים קבוצתיים",
        url: "/collectives"
      }
    ]}, 
    {
    title: "מידע אישי",
    anchor: [{
        title: "מידע אישי ללקוח",
        url: "https://info.yedidim.co.il/AnYedidim/websiteRegistration.aspx",
        target: "_blank"
      },
      {
        title: "מידע אישי למעסיק",
        url: "https://info.yedidim.co.il/yedidimportaltest/loginEmployer",
        target: "_blank"
      }]
  }],
  [{
      title: "משהו השתנה אצלך?",
      anchor: [{
          title: "עבודה חדשה",
          url: "/start-new-work"
        },
        {
          title: "שינויים במהלך העבודה",
          url: "/life-changes"
        },
        {
          title: "עזיבת עבודה",
          url: "/finish-work"
        },
        {
          title: "לקראת פרישה",
          url: "/retirement-process"
        }
      ]
    },
    {
      title: "צריך עזרה?",
      anchor: [{
          title: "מה עושים כדי לתבוע את הביטוח?",
          url: "/process-health-insurance"
        },
        {
          title: "טפסים",
          url: "/forms"
        },
        {
          title: "צור איתנו קשר",
          url: "/#section_6"
        },
      ]
    }
  ]
];

export function getMenuPanel() {
  return menuPanel;
}